import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1c18f537 = () => interopDefault(import('../pages/aktuality/index.vue' /* webpackChunkName: "pages/aktuality/index" */))
const _6f4e1c79 = () => interopDefault(import('../pages/chystam-party/index.vue' /* webpackChunkName: "pages/chystam-party/index" */))
const _42f1fcae = () => interopDefault(import('../pages/cookies/index.vue' /* webpackChunkName: "pages/cookies/index" */))
const _196d61ca = () => interopDefault(import('../pages/party-cepice/index.vue' /* webpackChunkName: "pages/party-cepice/index" */))
const _60c391bd = () => interopDefault(import('../pages/vychytavky/index.vue' /* webpackChunkName: "pages/vychytavky/index" */))
const _1a6253ef = () => interopDefault(import('../pages/aktuality/_slug.vue' /* webpackChunkName: "pages/aktuality/_slug" */))
const _6d977b31 = () => interopDefault(import('../pages/chystam-party/_slug.vue' /* webpackChunkName: "pages/chystam-party/_slug" */))
const _5f0cf075 = () => interopDefault(import('../pages/vychytavky/_slug.vue' /* webpackChunkName: "pages/vychytavky/_slug" */))
const _82c75b1a = () => interopDefault(import('../pages/chystam-party/_party/_item.vue' /* webpackChunkName: "pages/chystam-party/_party/_item" */))
const _90f0bfa4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktuality",
    component: _1c18f537,
    name: "aktuality___cs"
  }, {
    path: "/aktuality",
    component: _1c18f537,
    name: "aktuality___sk"
  }, {
    path: "/chystam-party",
    component: _6f4e1c79,
    name: "chystam-party___cs"
  }, {
    path: "/chystam-party",
    component: _6f4e1c79,
    name: "chystam-party___sk"
  }, {
    path: "/cookies",
    component: _42f1fcae,
    name: "cookies___cs"
  }, {
    path: "/cookies",
    component: _42f1fcae,
    name: "cookies___sk"
  }, {
    path: "/party-cepice",
    component: _196d61ca,
    name: "party-cepice___cs"
  }, {
    path: "/party-cepice",
    component: _196d61ca,
    name: "party-cepice___sk"
  }, {
    path: "/vychytavky",
    component: _60c391bd,
    name: "vychytavky___cs"
  }, {
    path: "/vychytavky",
    component: _60c391bd,
    name: "vychytavky___sk"
  }, {
    path: "/aktuality/:slug",
    component: _1a6253ef,
    name: "aktuality-slug___cs"
  }, {
    path: "/aktuality/:slug",
    component: _1a6253ef,
    name: "aktuality-slug___sk"
  }, {
    path: "/chystam-party/:slug?",
    component: _6d977b31,
    name: "chystam-party-slug___cs"
  }, {
    path: "/chystam-party/:slug?",
    component: _6d977b31,
    name: "chystam-party-slug___sk"
  }, {
    path: "/vychytavky/:slug",
    component: _5f0cf075,
    name: "vychytavky-slug___cs"
  }, {
    path: "/vychytavky/:slug",
    component: _5f0cf075,
    name: "vychytavky-slug___sk"
  }, {
    path: "/chystam-party/:party?/:item",
    component: _82c75b1a,
    name: "chystam-party-party-item___cs"
  }, {
    path: "/chystam-party/:party?/:item",
    component: _82c75b1a,
    name: "chystam-party-party-item___sk"
  }, {
    path: "/",
    component: _90f0bfa4,
    name: "index___cs"
  }, {
    path: "/",
    component: _90f0bfa4,
    name: "index___sk"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
